import { Routes, Route } from "react-router-dom"

import './styles/index.scss';
import Layout from "./Layout"
import Home from "./pages/Home"
import AboutUs from "./pages/AboutUs";
import Career from "./pages/Career";
import Contacts from "./pages/Contacts";
import ScrollToTop from "./helpers/scrollToTop";

function App() {
  return (
    <Layout>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="about" element={<AboutUs/>} />
        <Route path="contacts" element={<Contacts/>} />
        <Route path="clients" element={<Home/>} />
        <Route path="services" element={<Home/>} />
        <Route path="career" element={<Career/>} />
      </Routes>
    </Layout>
  );
}

export default App;
