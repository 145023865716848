import {useState} from "react";

const list = [
  'Code',
  'DevOps',
  'Cloud',
  'DBMS',
  'QA',
  'AI'
]

const TechItem = ({name}) => <div
  className="py-[0.625rem] px-[1.25rem] border border-saccent rounded-full flex items-center">
  <img className="max-w-[25px] lg:max-w-[30px]" src={`/img/tech/${name.replace(/[^a-z]/gi, '_').toLowerCase()}.png`}
       alt={name}/>
  <div className="pl-3">{name}</div>
</div>

const getTechList = list => <div className="flex gap-[1.25rem] flex-wrap">
  {list.map(item => <TechItem key={item} name={item}/>)}
</div>

const getDescription = (selectedTech) => {
  switch (selectedTech) {
    case 'Code':
      return getTechList(['Java', 'JavaScript', 'Python', 'PHP', 'Go', 'Ruby', 'C#', 'C/C++', 'Kotlin', 'Swift'])
    case 'DevOps':
      return getTechList(['CI/CD', 'Jenkins', 'Terraform', 'Ansible', 'Docker', 'Kubernetes'])
    case 'Cloud':
      return getTechList(['Amazon Web Services', 'Google Cloud', 'Microsoft Azure'])
    case 'DBMS':
      return getTechList(['MySQL', 'SQL', 'NoSQL', 'PostgreSQL', 'MongoDB', 'Oracle'])
    case 'QA':
      return getTechList(['Selenium', 'Jmeter', 'SoapUI', 'LoadRunner'])
    case 'AI':
      return getTechList(['TensorFlow', 'PyTorch', 'Keras', 'Reinforcement Learning', 'RNN'])
    default:
      return <div>Разрабатываем программное обеспечение с использованием Agile методологий и фреймворков, включая Scrum,
        Kanban - для небольших команд, SAFe, Less, Nexus - для более крупных
        и сложных проектов.</div>
  }
}

function Technologies() {
  const [selectedTech, setSelectedTech] = useState()
  return <div>
    <div className="grid lg:hidden">
      <div className="grid grid-container grid-cols-1 gap-10">
        {list.map(elem =>
          <div>
            <div
              key={elem}
              className={`rounded-t-[2px] rounded-b-0 ${selectedTech === elem ? 'bg-saccent rounded-b-[2px]' : ''}
          grid-item py-[0.625rem] cursor-pointer border-b-4 pl-1 font-text-regular bg-saccent border-saccent w-[6.25rem]`}
            >
              {elem}
            </div>
            <div className="mt-4">
              {getDescription(elem)}
            </div>
          </div>
        )}

      </div>
    </div>

    <div className="hidden lg:grid grid-container grid-cols-1 md:grid-cols-2 gap-8">
      <div className="grid-item">
        <div className="grid grid-container grid-cols-3 gap-10">
          {list.map(elem => <div
            key={elem}
            className={`rounded-t-[2px] rounded-b-0 ${selectedTech === elem ? 'bg-saccent rounded-b-[2px]' : ''}
          grid-item py-[0.625rem] cursor-pointer border-b-4 pl-1 font-text-regular hover:bg-saccent border-saccent w-[6.25rem]
          2xl:w-[10rem]`}
            onMouseOver={() => {
              if (selectedTech !== elem) {
                setSelectedTech(elem)
              } else {
                setSelectedTech('')
              }
            }}
            onMouseOut={() => {
                setSelectedTech('')
            }}
          >
            {elem}
          </div>)}
        </div>
      </div>
      <div className="grid-item font-text-regular">
        {getDescription(selectedTech)}
      </div>
    </div>
  </div>
}

export default Technologies


