const data = [
  {
    header: 'Планирование',
    image: 'planning.png',
    text: 'Определяем техническое задание. Cогласовываем этапы и сроки.'
  },
  {
    header: 'Разработка и тестирование',
    image: 'testing.png',
    text: 'Итеративный подход к разработке. Многоуровневое тестирование.'
  },
  {
    header: 'Поддержка',
    image: 'support.png',
    text: 'Поддерживаем продукт после его запуска в эксплуатацию.'
  }
]

function WorkProcess({header, image, text}) {
  return <div className="grid-item text-center p-[2rem] border rounded-3xl border-1 border-saccent">
    <div>
      <img className="mx-auto" src={`/img/${image}`} alt={image}/>
    </div>
    <div className="font-header-h3 my-4">{header}</div>
    <div className="font-text-regular">{text}</div>
  </div>
}

function WorkProcesses() {
  return <div className="grid
  grid-container
   grid-cols-1 gap-[1rem]
   lg:grid-cols-3 2xl:px-[8rem] 3xl:px-[12rem] 2xl:gap-[2rem] 3xl:gap-[4rem]">
    {data.map(({header, image, text}) =>
    <WorkProcess header={header} image={image} text={text} />)}
  </div>
}

export default WorkProcesses
