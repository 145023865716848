import { Link } from "react-router-dom";

function MobileMenu({ hideMenu, menuVisible, location, menu, showDiscussForm }) {

  return <div className={`${menuVisible ? "visible" : ""} font-nunito-r-13 lg:hidden mobile-menu mt-0 bg-sblack text-sgray`}>
   
    <ul className="">
      {menu.map(({ title, route }) => (<li className={location.pathname === route ? "active" : ""}><Link
        to={route}
        className="block text-center hover:bg-green-500 transition duration-300 text-[1.5rem] p-8"
        onClick={hideMenu}
      >
        {title}
      </Link></li>
      ))}
      <li>
        <Link
          to="#"
          className="block text-center hover:bg-green-500 transition duration-300 text-[1.5rem] p-8"
          onClick={showDiscussForm}
        >
           ОБСУДИТЬ ПРОЕКТ
        </Link></li>
    </ul>
  </div>
}

export default MobileMenu
