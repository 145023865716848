import { useState } from "react";
import CVForm from "./CVForm";

const CV = 'CV';
const INTERVIEW = 'interview';
const OFFER = 'offer';
const WORK = 'work';

const getDescription = (selectedItem) => {
    switch (selectedItem) {
        case CV:
            return [
                `Нам важно знать о технологиях, с которыми Вы умеете работать, о `,
                `проектах, в которых Вы принимали участие и о Вашей роли в этих `,
                `проектах. `,
                `Нам важно узнать о Ваших результатах и достижениях!`
            ];
        case INTERVIEW:
            return [
                `Во время собеседования Вам будет предложено решить практические `,
                `задачи. Это интересно! `,
                `Мы работаем в распределенных командах, поэтому иногда возникает `,
                `необходимость провести дополнительное собеседование с участием `,
                'наших иностранных партнеров.'
            ];
        case OFFER:
            return [
                `Успешно прошедшему собеседование кандидату мы высылаем Job Offer в `,
                `письменном виде, стараемся сформулировать все предлагаемые условия `,
                `максимально четко и полно.`
            ];
        case WORK:
            return [
                `Мы постараемся, чтобы Ваш первый рабочий день в Спринтерре прошел `,
                `интересно и продуктивно: Вы познакомитесь со своей командой, Вам `,
                `выдадут все необходимое оборудование и помогут его настроить. И, `,
                `конечно же мы оформим все необходимые документы и проведем `,
                'инструктаж по технике безопасности!'
            ];
        default:
            return [
                `Для того, чтобы присоединиться к нашей команде нужно сделать четыре `,
                `шага: отправьте нам свое резюме, пройдите техническое собеседование с `,
                `нашими ведущими инженерами, получите приглашение о работе и `,
                `оформите необходимые документы. `,
                'Подробнее о каждом шаге можно узнать в соответствующем разделе.'
            ]
    }
}

const SendCVModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed flex-grow content-center w-full top-0 left-0 h-full z-50 bg-sblack">
            <button onClick={onClose} className="absolute top-2 right-2 font-header-h2 mr-6 cursor-pointer text-swhite">
                &times;
            </button>

            <CVForm close={onClose}/>
        </div>
    );
};

function ItemPoint({ number, title, onMouseOver, onMouseOut }) {
    return (
        <div className="flex flex-row w-96 h-20 items-start ">
            <div className="flex w-24 justify-end number opacity-[0.2] text-swhite">
                {number}
            </div>

            <div
                className={`w-full h-14 mt-6 ml-14 flex items-center font-header-h3 text-weight-400 rounded-[2px] grid-item cursor-pointer border-b-4 pl-1 
            hover:bg-swhite hover:bg-opacity-20 hover:border-opacity-0 border-swhite border-opacity-20`}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                {title}
            </div>
        </div>
    );
}

function ItemPointSmall({ number, title, item }) {
    return (
            <div className="flex flex-row pt-10">
                <div className="hidden md:flex w-14 items-start justify-end pt-4 mr-14 number opacity-[0.2] text-swhite">
                    {number}
                </div>

                <div className="w-full">
                    <div className="flex flex-row justify-between">
                        <div
                            className={"flex xs:w-9/12 sm:w-1/2 items-center h-14 px-4 font-header-h3 rounded-[2px] grid-item bg-swhite bg-opacity-20"}
                        >
                            {title}
                        </div>
                        <div className="pr-10">
                            <img src="/img/caret.svg" alt="Caret-icon" />
                        </div>
                    </div>

                    <div className="w-full pt-10">
                        {getDescription(item).join('')}
                    </div>
                </div>

            </div>
    );

}

function Employment() {
    const [selectedItem, setSelectedItem] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onMouseOver = (item) => {
        if (selectedItem !== item) {
            setSelectedItem(item);
        } else {
            setSelectedItem('');
        }
    };

    const onMouseOut = () => {
        setSelectedItem('');
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="flex flex-col mt-14">
            <div className="grid lg:hidden">
                <div className="grid grid-container grid-cols-1">
                    <div className="grid-item col-span-2 font-text-regular justify-center mt-4">
                        <div className="w-full">
                            {getDescription().join('')}
                        </div>

                        <ItemPointSmall number={1} title="Резюме" item={CV} />
                        <ItemPointSmall number={2} title="Собеседование" item={INTERVIEW} />
                        <ItemPointSmall number={3} title="Предложение о работе" item={OFFER} />
                        <ItemPointSmall number={4} title="Выход на работу" item={WORK} />
                    </div>

                </div>
            </div>

            <div className="hidden lg:flex flex-row h-2/3 justify-between gap-24">
                <div>
                    <ItemPoint number={1} title="Резюме" onMouseOver={() => onMouseOver(CV)} onMouseOut={onMouseOut} />
                    <ItemPoint number={2} title="Собеседование" onMouseOver={() => onMouseOver(INTERVIEW)} onMouseOut={onMouseOut} />
                    <ItemPoint number={3} title="Предложение о работе" onMouseOver={() => onMouseOver(OFFER)} onMouseOut={onMouseOut} />
                    <ItemPoint number={4} title="Выход на работу" onMouseOver={() => onMouseOver(WORK)} onMouseOut={onMouseOut} />
                </div>

                <div className="font-text-regular mt-4">
                    <div>{getDescription(selectedItem).join('')} </div>
                </div>
            </div>

            <div className="w-full text-center ">
                <input type="submit" onClick={openModal}
                    className="cursor-pointer font-text-small uppercase px-6 py-4 mt-24 mb-2 rounded-full text-swhite border-sblack border-[1px]
                  transition-all
                  hover:bg-sblack
                  "
                    value="Отправить резюме" />
            </div>

            <SendCVModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
}

export default Employment
