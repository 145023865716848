import Expertise from "./Expertise";
import LightBlock from "../components/LightBlock";
import Separator from "../components/Separator";
import DarkBlock from "../components/DarkBlock";
import Technologies from "../components/Technologies";
import WorkProcesses from "../components/WorkProcesses";
import Projects from "../components/Projects";
import ContactForm from "../components/ContactForm";

function About() {
  return (
    <div id="about">
      <Separator className="rounded-t-3xl bg-sgray" />
      <LightBlock>
        <div className="grid grid-container grid-cols-1 md:grid-cols-2 gap-8">
          <div className="grid-item">
            <h2>Наша экспертиза</h2>
          </div>
          <div className="grid-item">
          </div>
        </div>
        <div className="grid grid-container grid-cols-1 md:grid-cols-2 gap-8 md:mt-[80px]">
          <div className="grid-item">
            <div className="max-w-[500px] font-text-regular">
              Высокая инженерная культура, гибкая методология разработки и использование современных технологий
              позволяют
              нам создавать новые решения и легко вливаться в команду заказчика, обогащая ее своими компетенциями.
            </div>
          </div>
          <div className="grid-item">
            <Expertise />
          </div>
        </div>
      </LightBlock>
      <div className="bg-sgray">
        <Separator className="rounded-t-3xl bg-sblack" />
      </div>
      <DarkBlock>
        <h2 className="w-full text-center pb-[3.125rem]">Технологии</h2>
        <Technologies />
      </DarkBlock>
      <Separator className="rounded-t-3xl bg-sgray" />
      <LightBlock>
        <h2 className="w-full text-center pb-[3.125rem]">Рабочий процесс</h2>
        <WorkProcesses />
      </LightBlock>
      <div className="bg-sgray">
        <Separator className="rounded-t-3xl bg-saccent" />
      </div>
      <LightBlock className="bg-saccent pb-[4rem]">
        <div className="projects-grid">
          <h2 className="w-full text-center">Проекты</h2>
          <div className="grid-item justify-end
          projects-container
          pt-[3rem]">
            <Projects />
          </div>
        </div>
        <Separator className="h-[150px] bg-saccent" />
        <div className="flex justify-center">
          <div className="bg-swhite w-full md:w-10/12 max-w-[1096px] rounded-[2rem] p-[1rem]">
            <div className="flex flex-col align-middle justify-center items-center">
              <h2 className="font-header-h2 text-center mb-8 mt-2" id="contact">Хотите обсудить проект?</h2>
              <ContactForm />
            </div>
          </div>
        </div>
      </LightBlock>
      <Separator className="bg-sblack h-[400px] mt-[-400px]" />
    </div>
  );
}

export default About;
