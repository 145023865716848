import {Link} from "react-router-dom";

function DesktopMenu({location, menu}) {
  return <div className="flex w-100 ">
    <div className="hidden sm:flex space-x-1 text-xs">
      {menu.map(({title, route}) => (<Link
          to={route}
          rel="preload"
          className={`py-4 px-2 text-swhite whitespace-nowrap hover:underline
                      ${location.pathname === route ? "text-sgreen" : ""}`}
        >
          {title}
        </Link>
      ))}
    </div>
  </div>
}

export default DesktopMenu
