import {Link} from 'react-router-dom';
import menu from './data/menu';
import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import DesktopMenu from "./components/DesktopMenu";
import MenuToggle from "./components/MenuToggle";
import MobileMenu from "./components/MobileMenu";
import ContactForm from "./components/ContactForm";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function scrollToId(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

function scrollToContact() {
  scrollToId('contact')
  document.getElementById('name').focus();
}

function Layout({children}) {
  const location = useLocation();
  const [menuVisible, setMenu] = useState(false)
  const toggleMenu = () => setMenu(!menuVisible)
  const hideMenu = () => setMenu(false)
  const [isDiscussForm, setShowDiscussForm] = useState(false)

  const hideDiscussForm = () => {
    setShowDiscussForm(false)
  }
  const showDiscussForm = () => {
    setShowDiscussForm(true)
  }

  return (
    <div className="flex w-full h-full flex-col bg-sblack">

    {
      isDiscussForm ? <div className="fixed flex-grow content-center w-full top-0 h-full z-50 bg-swhite">
          <span className="absolute right-0 top-0 font-header-h2 mr-6 cursor-pointer" onClick={hideDiscussForm}>&times;</span>
            <h2 className="font-header-h2 text-center mb-8 mt-2" id="contact">Хотите обсудить проект?</h2>
        <ContactForm successCallback={hideDiscussForm}/>
      </div>
        : ''
    }

      <nav className="w-full fixed z-20">
        <Link to="/">
          <div className="logo"/>
        </Link>

        <div className="flex justify-end items-center bg-sblack
          lg:justify-center
          h-[75px]
          lg:h-[100px]
          ">
          <DesktopMenu location={location} menu={menu}/>

          <div className="absolute
           right-[5rem] rounded-[2rem] border-[1px] border-1 border-saccent h-[40px] w-[170px] text-center
           p-[12px] text-xs text-white transition-all
           opacity-0
           lg:opacity-100
           hover:bg-saccent cursor-pointer"
               onClick={location.pathname !== '/' ? showDiscussForm : scrollToContact}
          >
            ОБСУДИТЬ ПРОЕКТ
          </div>

          <MenuToggle toggleMenu={toggleMenu} menuVisible={menuVisible} />
        </div>
        <MobileMenu location={location} menu={menu} menuVisible={menuVisible} hideMenu={hideMenu} showDiscussForm={showDiscussForm}/>
      </nav>

      <div className="h-[75px]"></div>

      <main className="w-full">
        {children}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          style={{bottom:'100px'}}
          hideProgressBar={true}
        />
      </main>

      <footer className="w-full
              left-0 bottom-0
              flex flex-col justify-center items-center
              mt-auto
              bg-sblack
              text-white
              text-xs
              ">
        <div className="p-5 flex align-center items-center flex-col">
          <div> © ООО «Спринтерра», 2022—{new Date().getFullYear()}. Все права защищены.</div>
        </div>

      </footer>
    </div>
  );
}

export default Layout;
