import Advantage from "../components/Advantage";

function Title() {
  return (
    <div
      className="bg-[url('../../public/img/title.jpg')] [background-position-y:-0px] bg-cover bg-no-repeat title text-swhite spaddinglogo
      [background-position-x:center]
      ">
      <div className="h-screen flex flex-grow">
        <div className="h-screen flex flex-col flex-grow items-center justify-around">
          <div className="text-center">
            <h1 className="w-full text-center mb-[0rem] pb-[0.75rem] ">
              Мы разрабатываем<br/>
              IT-решения для Ваших задач
            </h1>
            <div className="font-title-regular">Спринтерра - аккредитованная ИТ-компания</div>
            <div className="font-title-regular">Мы создаем продукты различной сложности и направленности</div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-full md:w-10/12">
              <div className="grid grid-container grid-cols-3">
                <Advantage
                  header="20+ лет"
                >
                  опыт разработки решений<br/>в ИТ-сфере
                </Advantage>
                <Advantage
                  header="Agile-культура"
                >
                  создаем продукты быстро<br/>и эффективно
                </Advantage>
                <Advantage
                  header="24 / 7 / 365"
                >
                  поддержка работы наших<br/>решений
                </Advantage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;
