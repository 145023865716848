import LightBlock from "../components/LightBlock";
import Separator from "../components/Separator";

function Contacts() {
  return (
    <div id="contacts">
      <LightBlock>
        <div className="w-full justify-center text-sblack">
            <div className="text-center">
                <h2>Мы открыты к сотрудничеству</h2>
            </div>

            <Separator className="h-[50px]"/>

            <div className="text-center font-text-regular space-y-2.5">
                <div>+7 (498) 719-0-917</div>
                <div>info@sprinterra.ru</div>
                <div>Московская обл., г. Королёв,  ул. Дзержинского, д. 29, офис 5</div>
            </div>

            <Separator className="h-[70px]"/>

            <div className="flex w-full justify-center">
                <iframe title="map" width="50%" height="500" frameBorder="0"
                            scrolling="no" marginHeight="0"
                            marginWidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%D0%B3.%20%D0%9A%D0%BE%D1%80%D0%BE%D0%BB%D0%B5%D0%B2,%20%D1%83%D0%BB.%20%D0%94%D0%B7%D0%B5%D1%80%D0%B6%D0%B8%D0%BD%D1%81%D0%BA%D0%BE%D0%B3%D0%BE,%2029.+(Sprinterra)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        <a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe>

            </div>
        </div>
      </LightBlock>

    </div>
  );
}

export default Contacts;


