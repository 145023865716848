import TextSections from "./TextSections";

function Value({ img, sections, signature }) {
    return (
        <div className="flex items-center">

            <div className="flex flex-row flex-grow justify-center">
                <img src={img} className="w-32 rounded-full mr-6" alt="Project Item" />
            </div>


            <div className="flex flex-col">
                <div className="italic">
                    <TextSections sections={sections} />
                </div>
                <div className="pt-4">
                    {signature}
                </div>
            </div>
        </div>
    );
}


function Values() {
    return (
        <div className="flex justify-center">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[8rem] gap-y-[5rem]">
                <Value
                    img="/img/values/values_1.png"
                    sections={[
                        `“Профессиональный рост необходим`,
                        `каждому, хорошо, когда в компании`,
                        `создаются возможности для`,
                        `горизонтального и вертикального роста`,
                        `сотрудников”`
                    ]}
                    signature={"Дмитрий, Team Lead"}
                />
                <Value
                    img="/img/values/values_2.png"
                    sections={[
                        `“Доверие - это основа для`,
                        `эффективного сотрудничества, поэтому`,
                        `для работы в команде важны такие`,
                        `качества, как открытость и терпимость к`,
                        `другим точкам зрения”`
                    ]}
                    signature={"Анна, Technical Project Manager"}
                />
                <Value
                    img="/img/values/values_3.png"
                    sections={[
                        `“Практический опыт очень`,
                        `важен. Теоретических знаний не`,
                        `достаточно для эффективного решения`,
                        `задач, поэтому начинающим`,
                        `специалистам хорошо иметь наставника”`
                    ]}
                    signature={"Дмитрий, Head of Tech Support"}
                />
                <Value
                    img="/img/values/values_4.png"
                    sections={[
                        `“Работа в ИТ-компании предполагает`,
                        `постоянное профессиональное`,
                        `развитие. Нам необходимо быть в курсе`,
                        `развития тенденций, иначе навыки`,
                        `могут утратить свою актуальность”`
                    ]}
                    signature={"Даниил, Senior Software Enginier"}
                />
                <Value
                    img="/img/values/values_5.png"
                    sections={[
                        `“Здорово, когда атмосфера в коллективе`,
                        `дружеская: с коллегами легко и приятно`,
                        `пообщаться и по рабочим, и по другим,`,
                        `не менее важным, вопросам”`
                    ]}
                    signature={"Лилия, HR Specialist"}
                />
                <Value
                    img="/img/values/values_6.png"
                    sections={[
                        `“Важно, чтобы работа нравилась и`,
                        `приносила удовольствие. Ведь мы`,
                        `проводим на работе значительную часть`,
                        `нашего времени”`
                    ]}
                    signature={"Дмитрий, Business Analyst"}
                />
            </div>
        </div>
    );
}

export default Values;
