import Title from './Title'
import About from './About'

function Home() {
  return (
    <>
      <Title/>
      <About/>
    </>
  );
}

export default Home;
